import type { Category, Header } from "payload/generated-types"
import type { PropsWithChildren } from "react"

import { type LoaderFunctionArgs } from "@remix-run/node"
import { json } from "@remix-run/node"
import { Link, NavLink, Outlet, useLoaderData } from "@remix-run/react"
import { FiSidebar, FiSearch, FiMoon, FiSun } from "react-icons/fi"

import { ColorsCategory } from "@/lib/colors-category"
import { cn } from "@/lib/utils"

export const loader = async ({ context: { payload } }: LoaderFunctionArgs) => {
  const header = await payload.findGlobal({
    slug: "header"
  }) as Header

  const navItems = header.navItems?.map(({ id, link }) => ({
    id: id,
    target: link?.newTab ? "_blank" : undefined,
    color: link?.type === "reference" && link?.reference?.relationTo === "categories" ? (link?.reference?.value as Category).color : undefined,
    to: (link?.type === "reference" && link?.reference?.relationTo === "categories" ? `/${(link?.reference?.value as Category).slug}` : link?.url) as string,
    label: link?.type === "reference" && link?.reference?.relationTo === "categories" ? (link?.reference?.value as Category).title : link?.label,
  }))

  return json({
    header: {
      navItems
    }
  })
}

export default function Element() {
  const { header } = useLoaderData<typeof loader>()
  return (
    <div className="flex flex-col min-h-svh">
      <StickyHeader>
        <div className="container py-4 grid grid-cols-[60px_1fr_60px] justify-between items-center">
          <div>
            {/*
            <button>
              <FiSidebar />
              <span className="sr-only">Menu</span>
            </button>
            */}
          </div>
          <div className="grow flex justify-center">
            <Link to="/">
              <img src="/logo.webp" alt="Bubble Geek" title="Bubble Geek" className="h-6 lg:h-7 w-auto dark:hidden" width="185" height="28" />
              <img loading="lazy" src="/logo-dark.webp" alt="Bubble Geek" title="Bubble Geek" className="hidden dark:block h-6 lg:h-7 w-auto" width="185" height="28" />
            </Link>
          </div>
          <div className="flex gap-3 lg:gap-4 justify-end">
            <ThemeToggleButton />
            <SearchButton />
          </div>
        </div>
        <nav className="overflow-x-auto no-scrollbar border-t-2 border-border/20">
          <ul className="flex px-4 md:px-0 md:justify-center gap-4 font-medium whitespace-nowrap">
            {header.navItems?.map(({ id, to, label, target, color }) => (
              <li key={id} className="last:pr-4 md:last:pr-0">
                <NavLink to={to} target={target} className={({ isActive }) => cn("lowercase block py-2 underline-offset-[12px] decoration-2 hover:underline", isActive && "underline", color && ColorsCategory[color])}>{label}</NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </StickyHeader >
      <main className="grow">
        <Outlet />
      </main>
      <footer className="bg-gray-50 dark:bg-gray-900 mt-12">
        <div className="container">
          <div className="py-4 md:py-6">
            <Link to="/">
              <img loading="lazy" src="/logo.webp" alt="Bubble Geek" title="Bubble Geek" className="h-7 w-auto dark:hidden" width="185" height="28" />
              <img loading="lazy" src="/logo-dark.webp" alt="Bubble Geek" title="Bubble Geek" className="hidden dark:block h-7 w-auto" width="185" height="28" />
            </Link>
          </div>
          <div className="flex flex-col md:flex-row gap-4 justify-between md:items-center pb-4 text-sm">
            <p className="text-primary/50 md:ml-1">© Copyright 2024 BubbleGeek</p>
            <nav className="flex flex-col md:flex-row md:divide-x-2">
              <Link to="/politica-de-privacidade" className="my-0.5 md:px-2 text-link hover:underline">
                Política de Privacidade
              </Link>
              <Link to="/termos-e-condicoes" className="my-0.5 md:px-2 text-link hover:underline">
                Termos e Condições
              </Link>
              <Link to="/contato" className="my-0.5 md:px-2 text-link hover:underline">
                Contato
              </Link>
            </nav>
          </div>
        </div>
      </footer>
    </div>
  )
}

const StickyHeader = ({ children }: PropsWithChildren) => (
  <header id="header" className="sticky top-0 z-10 border-b-2 border-border/20 transition-transform duration-300 backdrop-blur-sm bg-background/90">
    {children}
    <script
      dangerouslySetInnerHTML={{
        __html: `
          (function() {
            var lastScrollTop = 0;
            var scrollThreshold = 700;
            var header = document.getElementById('header');

            if (!header) return;

            function updateHeaderPosition(currentScrollTop) {
              if (currentScrollTop > scrollThreshold) {
                if (currentScrollTop > lastScrollTop) {
                  hideHeader();
                } else {
                  showHeader();
                }
              } else {
                showHeader();
              }
            }

            function hideHeader() {
              header.style.transform = 'translateY(-100%)';
            }

            function showHeader() {
              header.style.transform = 'translateY(0)';
            }

            window.addEventListener('scroll', function() {
              var currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
              updateHeaderPosition(currentScrollTop);
              lastScrollTop = Math.max(currentScrollTop, 0);
            });
          })();
        `,
      }}
    />
  </header>
)

const ThemeToggleButton = () => (
  <button id="theme-toggle">
    <FiMoon className="dark:hidden hover:stroke-accent" />
    <FiSun className="hidden dark:block hover:stroke-accent" />
    <span className="sr-only">Trocar tema</span>
    <script dangerouslySetInnerHTML={{
      __html: `
          (function() {
            const button = document.getElementById('theme-toggle');
            const applyTheme = (theme) => {
              document.documentElement.classList.toggle('dark', theme === 'dark');
              localStorage.setItem('theme', theme);
            };

            button.addEventListener('click', () => {
              const currentTheme = document.documentElement.classList.contains('dark') ? 'dark' : 'light';
              const newTheme = currentTheme === 'dark' ? 'light' : 'dark';
              applyTheme(newTheme);
            });

            const savedTheme = localStorage.getItem('theme');
            if (savedTheme) {
              applyTheme(savedTheme);
            } else {
              const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
              applyTheme(prefersDarkScheme ? 'dark' : 'light');
            }
          })();
        `,
    }} />
  </button>
)

const SearchButton = () => (
  <Link to="/pesquisa">
    <FiSearch className="hover:stroke-accent" />
    <span className="sr-only">Pesquisar</span>
  </Link>
)